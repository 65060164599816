import React from "react";
import "./Footer.css";

const Footer: React.FC = () => (
  <div className="footer-container">
    <span>© 2023 Le Tour de Frankie. All rights reserved</span>
  </div>
);

export default Footer;
