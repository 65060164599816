import React, { useState } from "react";
import "./App.css";
import MainMenu from "./Components/MainMenu/MainMenu";
import Comingsoon from "./Components/ComingSoon/ComingSoon";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MapRoute from "./Components/Route/MapRoute";
import Footer from "./Components/Footer/Footer";
import Rules from "./Components/Rules/Rules";
import PastEditions from "./Components/PastEdtions/PastEdtions";
import Brevet from "./Components/Brevet/Brevet";
import NextEdition from "./Components/NextEdition/NextEdition";
import BrevetGravelSeries from "./Components/BrevetGravelSeries/BrevetGravelSeries";


function App() {
  const [switchLang, setSwitchLang] = useState(false);

  return (
    <div className="App-header">
      <BrowserRouter>
        <MainMenu />

        <Routes>
          <Route path="/" element={<Comingsoon />} />
          <Route path="/MapRoute" element={<MapRoute />} />
          <Route path="/Rules" element={<Rules />} />
          <Route path="/ltdf-2023" element={<PastEditions />} />
          <Route path="/brevet" element={<Brevet />} />
          <Route path="/ltdf-2024" element={<NextEdition />} />
          <Route path="/brevet-gravel-series" element={<BrevetGravelSeries />} />

        </Routes>

        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
